<template>
  <b-container class="p-4">
    <b-row>
      <b-col><h4>Marketing touchpoints</h4></b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form @submit="fetchMarketingTouchPoints()" inline>
          <b-form-group id="filter.clients" label="Clients:" label-for="filter.clients" class="mr-3">
            <multiselect
              v-model="filters.clients"
              tag-placeholder=""
              placeholder=""
              :options="clientsOptions"
              class="ml-2"
              track-by="id"
              label="display"
              :limit="10"
              :multiple="true"
              :taggable="false"></multiselect>
          </b-form-group>
          <b-form-group id="filter.date" label="Start/End Date:" class="mr-3" label-for="filter.date">
            <vue-ctk-date-time-picker format="YYYY-MM-DD" class="ml-2"
              data-testid="start-end-datepicker"
              label="Click to choose the date range"
              formatted="ll"
              range
              only-date
              :error="!dateRangeValidator(filters.date)"
              v-model="filters.date"/>
          </b-form-group>
          <b-form-group
            label-for="filter.keys"
            id="input-group-status"
            label="Status:"
            class="mr-3">
            <multiselect
              v-model="filters.statuses"
              tag-placeholder=""
              placeholder=""
              :options="statusesOptions"
              class="ml-2"
              :limit="5"
              :multiple="true"
              :taggable="false"></multiselect>
          </b-form-group>
          <b-form-group
            label-for="filter.status"
            id="input-group-status"
            label="Keys:"
            class="mr-3">
            <multiselect
              v-model="filters.keys"
              tag-placeholder=""
              placeholder=""
              :options="keysOptions"
              class="ml-2"
              :limit="3"
              :multiple="true"
              :taggable="false"></multiselect>
          </b-form-group>
          <b-button size="sm" class="float-right" variant="success" @click="fetchMarketingTouchPoints()">Apply</b-button>
        </b-form>
      </b-col>
    </b-row>

    <b-row v-if="filters.start_date">
      <b-col class="d-flex flex-column justify-content-center align-items-center mt-4">
        <small>Showing touchpoints between {{filters.start_date}} and {{filters.end_date}}</small>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-table :fields="touchpoints.fields" :items="touchpoints.data"
                 outlined striped small :show-empty="true" empty-text="No touchpoints configured">
          <template #cell(incentive)="data">
            <ul class="list-unstyled">
              <li><b>Description</b>: {{data.item.incentive_description}}</li>
              <li><b>Value</b>: {{data.item.incentive_value}}</li>
              <li><b>Currency</b>: {{data.item.incentive_currency}}</li>
            </ul>
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.status === 'created'" variant="secondary">{{data.item.status}}</b-badge>
            <b-badge v-if="data.item.status === 'canceled'" variant="warning">{{data.item.status}}</b-badge>
            <b-badge v-if="data.item.status === 'executed'" variant="success">{{data.item.status}}</b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loading"
                      @change="changePage"
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="filters.page_size"
                      size="md">
        </b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ListMarketingTouchpoints',
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: false,
      filters: {
        clients_id: null,
        clients: null,
        date: null,
        start_date: null,
        end_date: null,
        page_size: 10,
        statuses: null,
        keys: null,
      },
      keysOptions: [],
      statusesOptions: [],
      clientsOptions: [],
      currentPage: 1,
      total: 0,
      touchpoints: {
        loading: false,
        fields: [
          { key: 'client_id', label: 'Client ID' },
          { key: 'channel', label: 'Channel' },
          { key: 'date', label: 'Date (YYYY-MM-DD)' },
          { key: 'product', label: 'Product' },
          { key: 'incentive', label: 'Incentive' },
          { key: 'status', label: 'Status' },
          { key: 'key', label: 'Key' },
          { key: 'audience', label: 'Audience' },
        ],
        data: [],
      },
    };
  },
  beforeMount() {
    this.fetchTouchPointsKeys();
    this.fetchTouchPointsStatuses();
    this.loadClients();
    this.fetchMarketingTouchPoints();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.fetchMarketingTouchPoints();
    },
    fetchMarketingTouchPoints(resetPagination) {
      this.$bvModal.hide('modal-filters');
      this.touchpoints.loading = true;
      if (resetPagination) {
        this.currentPage = 1;
      }

      if (this.filters.date) {
        this.filters.start_date = this.filters.date.start;
        this.filters.end_date = this.filters.date.end;
      } else {
        this.filters.start_date = null;
        this.filters.end_date = null;
      }
      return axios.get('v1/marketing/touchpoint', {
        params: {
          page_number: this.currentPage,
          page_size: this.filters.page_size,
          client_id: JSON.stringify(this.filters.clients?.map(c => c.id)),
          start_date: this.filters.start_date,
          end_date: this.filters.end_date,
          statuses: this.filters.statuses && JSON.stringify(this.filters.statuses),
          keys: this.filters.keys && JSON.stringify(this.filters.keys),
        },
      })
        .then(response => {
          this.touchpoints.data = response.data.touchpoints;
          this.total = response.data.total;
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot fetch touchpoints', e);
        }).finally(() => {
          this.touchpoints.loading = false;
          this.touchpoints.loaded = true;
        });
    },
    fetchTouchPointsKeys() {
      return axios.get('v1/marketing/touchpoint/keys', {})
        .then(response => {
          this.keysOptions = response.data.keys;
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot fetch keys', e);
        });
    },
    fetchTouchPointsStatuses() {
      return axios.get('v1/marketing/touchpoint/statuses', {})
        .then(response => {
          this.statusesOptions = response.data.statuses;
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot fetch statuses', e);
        });
    },
    dateRangeValidator() {
      if (!this.filters.date) {
        return true;
      }
      const { start, end } = this.filters.date;
      return start && end;
    },
    async loadClients() {
      await axios
        .get('v1/clients')
        .then(data => {
          data.data.payload.forEach(p => {
            this.clientsOptions.push({ id: p.id, name: p.display_name, display: `${p.id} - ${p.display_name}` });
          });
        })
        .catch(response => {
          this.$noty.error('Unable to load Clients');
          console.error(response);
        });
    },
  },
};
</script>
